import { defineStore } from "pinia";

const defaultFormsData = {
  billingAddressForm: { start: false, isValid: true },
  deliveryAddressForm: { start: false, isValid: true },
  contactInformationForm: { start: false, isValid: true },
  storeInformationForm: { start: false, isValid: true },
  contactConfigurationForm: { start: false, isValid: true },
  transferForm: { start: false, isValid: true },
  modifierForm: { start: false, isValid: true },
  addonForm: { start: false, isValid: true },
  onboardingAccountInformationForm: { start: false, isValid: true },
  deliveryRunForm: { start: false, isValid: true },
  serviceInventoryInformationForm: { start: false, isValid: true },
  // companyForm: { start: false, isValid: true },
  // customerForm: { start: false, isValid: true },
  // contactForm: { start: false, isValid: true },
  // staffForm: { start: false, isValid: true },
  // locationForm: { start: false, isValid: true },
};

export const useValidationStore = defineStore("validation", () => {
  // State
  const forms = ref({
    billingAddressForm: { start: false, isValid: true },
    deliveryAddressForm: { start: false, isValid: true },
    onboardingAccountInformationForm: { start: false, isValid: true },
    deliveryRunForm: { start: false, isValid: true },
    serviceInventoryInformationForm: { start: false, isValid: true },
    // companyForm: { start: false, isValid: true },
    // customerForm: { start: false, isValid: true },
    // contactForm: { start: false, isValid: true },
    // staffForm: { start: false, isValid: true },
    contactInformationForm: { start: false, isValid: true },
    storeInformationForm: { start: false, isValid: true },
    contactConfigurationForm: { start: false, isValid: true },
    // locationForm: { start: false, isValid: true },
    transferForm: { start: false, isValid: true },
    addonForm: { start: false, isValid: true },
    modifierForm: { start: false, isValid: true },
  });

  // Getters
  const isAllFormsValid = computed(() => {
    return !Object.values(forms.value).some((f) => f.isValid === false);
  });

  const isAddressesValid = computed(() => {
    return (
      forms.value.billingAddressForm.isValid &&
      forms.value.deliveryAddressForm.isValid
    );
  });

  // Actions

  // All Forms
  function checkAllForms() {
    for (let f in forms.value) {
      forms.value[f].start = true;
    }
  }

  // Onboarding Account Information Form
  function checkOnboardingAccountInformation() {
    forms.value.onboardingAccountInformationForm.start = true;
  }

  // Billing Address Form
  function checkBillingAddressForm() {
    forms.value.billingAddressForm.start = true;
  }

  // Delivery Address Form
  function checkDeliveryAddressForm() {
    forms.value.deliveryAddressForm.start = true;
  }

  // Delivery Run Form
  function checkDeliveryRunForm() {
    forms.value.deliveryRunForm.start = true;
  }

  // Contact Configuration Form
  function checkServiceInventoryInformation() {
    forms.value.serviceInventoryInformationForm.start = true;
  }
  function checkContactConfigurationForm() {
    forms.value.contactConfigurationForm.start = true;
  }

  // Transfer Form
  function checkTransfer() {
    forms.value.transferForm.start = true;
  }

  // Modifier Form
  function checkModifier() {
    forms.value.modifierForm.start = true;
  }

  // Addon Form
  function checkAddon() {
    forms.value.addonForm.start = true;
  }

  // Reset Forms
  function resetForms() {
    forms.value = { ...defaultFormsData };
  }

  return {
    // State
    forms,
    // Getters
    isAllFormsValid,
    isAddressesValid,
    // Actions
    checkAllForms,
    checkOnboardingAccountInformation,
    checkBillingAddressForm,
    checkDeliveryAddressForm,
    checkDeliveryRunForm,
    checkServiceInventoryInformation,
    checkContactConfigurationForm,
    checkTransfer,
    checkModifier,
    checkAddon,
    resetForms,
  };
});
